import { Component, OnInit } from '@angular/core';
import { Client } from '../../../../shared/models/client.model';
import { Store } from '../../../../shared/models/store.model';
import { AuthService } from '../../../authentication/services/auth.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent implements OnInit {
  public isNewClient: boolean = false;
  public isNewStore: boolean = false;
  public isStoreCompleted: boolean = false;
  public goToClient: boolean = false;
  public client: Client = new Client();
  public store: Store = new Store();
  public linkAuth: string;
  public clients: Client[] = [];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}

  public signOut() {
    this.authService.logout();
  }
}
