<div class="app-container container-xxl ">
    <div class="container mt-5">
        <div class="row">
            <h1 class="text-white">Your Atomix Clients</h1>
        </div>
        <div class="row">
            <div class="col-xs-6 col-md-6 col-sm-6 col-lg-3 mb-3">
                <div class="card h-150px h-sm-200 mb-4 cursor-pointer" (click)="createClient()">
                    <div class="card-body d-flex flex-column align-items-center justify-content-center text-center">
                        <div class="row card-title ">
                            <span class="bi bi-plus-lg icon-add text-primary"></span>
                            <h3 class="text-primary">Add Client</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xs-6 col-md-6 col-sm-6 col-lg-3 mb-3" *ngFor="let client of clients">
                <app-client-card [client]="client" (selected)="selectClient($event)"></app-client-card>
            </div>
        </div>
    </div>
</div>