import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Notify, Report } from 'notiflix';
import { ClientService } from '../../services/client.service';
import { ClientValidator } from '../../validators/ClientValidator';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewClientComponent implements OnInit {
  @Output() clientCreated = new EventEmitter<any>();
  public clientName;
  public success = false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private clientService: ClientService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.clientName = this.fb.control(
      '',
      [Validators.required],
      [
        ClientValidator.createUniqueClientValidator(
          this.clientService,
          'atomix'
        ),
      ]
    );
  }

  submitForm() {
    this.spinner.show('createClient');
    this.clientService.createClient(this.clientName.value, 'atomix').subscribe({
      next: (client) => {
        Report.success(
          'Success', // Title
          'Client created successfully!', // Message
          'Ok', // Button text
          () => {
            // Callback function triggered when the 'Ok' button is clicked
            this.activeModal.close();
            this.clientCreated.emit(client); // Notify parent component to refresh the view
          }
        );
      },
      error: (error) => {
        Notify.failure('Store creation failed: ' + error.message);
      },
      complete: () => this.spinner.hide('createClient'),
    });
  }
}
