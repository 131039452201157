import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './core/components/layout/layout.component';
import { StartComponent } from './features/admin/components/start/start.component';
import { ShopifyAuthVerifyComponent } from './features/authentication/components/shopify-auth-verify/shopify-auth-verify.component';
import { AuthGuard } from './features/authentication/guards/auth.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./features/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'error',
    loadChildren: () =>
      import('./features/errors/errors.module').then((m) => m.ErrorsModule),
  },
  {
    path: 'start',
    canActivate: [AuthGuard],
    component: StartComponent,
  },
  {
    path: 'shopify/verify',
    component: ShopifyAuthVerifyComponent,
  },
  {
    path: 'client',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/clients/clients.module').then((m) => m.ClientsModule),
  },
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: 'start' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
