<div id="kt_app_sidebar_menu_wrapper" class="app-sidebar-wrapper hover-scroll-overlay-y my-5" data-kt-scroll="true"
  data-kt-scroll-activate="true" data-kt-scroll-height="auto"
  data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer"
  data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px" data-kt-scroll-save-state="true">
  <div class="menu menu-column menu-rounded menu-sub-indention px-3" id="#kt_app_sidebar_menu" data-kt-menu="true"
    data-kt-menu-expand="false">
    <div class="menu-item">
      <a class="menu-link without-sub" routerLink="/client/stores" routerLinkActive="active">
        <span class="menu-icon">
          <app-keenicon name="shop" class="fs-2"></app-keenicon>
        </span>
        <span class="menu-title">Store</span>
      </a>
    </div>
  </div>
</div>