import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';
import { Auth, GoogleAuthProvider, signInWithPopup } from '@angular/fire/auth';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  defaultAuth: any = {
    email: null,
    password: null,
  };
  loginForm: FormGroup;
  hasError: boolean;
  returnUrl: string;
  isLoading: boolean = false;
  public messageError: string;
  googleAuthProvider: GoogleAuthProvider;
  isGoogleLoading$: Observable<boolean>; // Observable to track loading state
  public googleError: boolean = false;
  public googleErrorMessage: string = '';

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private analytics: Analytics = inject(Analytics),
    private afAuth: Auth
  ) {
    this.googleAuthProvider = new GoogleAuthProvider();
    this.isGoogleLoading$ = new BehaviorSubject<boolean>(false); // Initialize as false
    if (this.authService.currentUserValue) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.initForm();
    this.returnUrl =
      this.route.snapshot.queryParams['returnUrl'.toString()] || '/';
  }

  get f() {
    return this.loginForm.controls;
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: [
        this.defaultAuth.email,
        Validators.compose([
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(320),
        ]),
      ],
      password: [
        this.defaultAuth.password,
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
        ]),
      ],
    });
  }

  onSubmit() {
    this.isLoading = true;
    this.hasError = false;
    this.authService
      .login(this.f.email.value, this.f.password.value)
      .then((isOk: boolean | undefined) => {
        logEvent(this.analytics, 'login', { method: 'email' });
        if (isOk) {
          this.router.navigate(['/start']);
        } else {
          this.hasError = true;
        }
        this.isLoading = false;
      })
      .catch((err) => {
        console.error('Login error:', err); // Log the error for debugging
        this.hasError = true;
        this.messageError = 'The authentication credentials are incorrect.';
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  onForgotPassword() {
    this.router.navigate(['auth/forgot-password']);
  }

  isGoogleLoading = false;

  async signInWithGoogleRedirect() {
    try {
      await this.authService.signInWithGoogleRedirect();
    } catch (error) {
      // Handle errors here
      console.error('Error during Google sign-in:', error);
    }
  }

  async signInWithGooglePopUp() {
    // Show loading indicator
    // this.isGoogleLoading = true;

    // Set loading state to true
    this.setGoogleLoadingState(true);

    this.googleAuthProvider.addScope(
      'https://www.googleapis.com/auth/userinfo.email'
    );
    this.googleAuthProvider.addScope(
      'https://www.googleapis.com/auth/userinfo.profile'
    );

    // Sign in with Google using Firebase Authentication
    // await signInWithRedirect(this.afAuth, this.googleAuthProvider);

    signInWithPopup(this.afAuth, this.googleAuthProvider)
      .then((userCredential) => {
        const credential =
          GoogleAuthProvider.credentialFromResult(userCredential);
        const token = credential.accessToken;
        // Handle successful Google login here
        const user = userCredential.user;
        console.log('Logged in with Google:', user);
      })
      .catch((error) => {
        if (error.code === 'auth/popup-blocked') {
          this.googleError = true;
          this.googleErrorMessage =
            'Google sign-in popup is blocked. Please enable pop-ups in your browser settings.';
        } else {
          // Handle errors here
          console.error('Google login error:', error);
        }
      })
      .finally(() => {
        // Hide loading indicator
        // this.isGoogleLoading = false;

        // Set loading state to false
        this.setGoogleLoadingState(false);
      });
  }

  private setGoogleLoadingState(isLoading: boolean) {
    (this.isGoogleLoading$ as BehaviorSubject<boolean>).next(isLoading);
  }

  ngOnDestroy() {}
}
