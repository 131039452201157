import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  NgControl,
} from '@angular/forms';
import { LoggerService } from 'src/app/shared/services/logger.service';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownInputComponent),
      multi: true,
    },
  ],
})
export class DropdownInputComponent implements ControlValueAccessor {
  @Input() name: string = 'default';
  @Input() control: FormControl;
  @Input() controlName: string;
  @Input() options: { [key: string]: string } = {}; // Assuming options are an object with key-value pairs
  @Input() selected: string = '';
  @Input() readOnly: boolean = false;
  @Output() change = new EventEmitter<string>();

  private _onChange: (v: string) => void;
  private _onTouch: () => void;
  objectKeys = Object.keys;
  value: any;
  ngControl: NgControl;

  constructor(private logger: LoggerService, private injector: Injector) {}

  ngOnInit() {
    this.value = this.selected;
    setTimeout(() => {
      this.ngControl = this.injector.get(NgControl, null);
      if (this.ngControl != null) {
        this.ngControl.valueAccessor = this;

        // Subscribe to value changes
        this.ngControl.control.valueChanges.subscribe((value) => {
          this.logger.log(`Value changed:', ${value}`);
          //TODO: Add custom logic here
        });

        // Subscribe to status changes
        this.ngControl.control.statusChanges.subscribe((status) => {
          this.logger.log(`Status changed:', ${status}`);
          //TODO: React to status change, e.g., showing or hiding validation messages
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options?.currentValue) {
      //TODO: Ensure options is defined and not null
      this.logger.log(`changed options: ${JSON.stringify(this.options)}`);
    }
  }

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this._onChange = (value: any) => {
      console.log('Changed DropDownInput Value: ', value);
      fn(value);
    };
  }

  onChange(value: string): void {
    this._onChange(value);
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  onTouch(): void {
    this._onTouch();
  }

  get isValid(): boolean {
    return this.ngControl?.control?.valid ?? true;
  }

  get isTouched(): boolean {
    return this.ngControl?.control?.touched ?? false;
  }

  // Method to call when dropdown value changes
  selectOption(value: string): void {
    this.value = value;
    this.onChange(value);
    this.change.emit(value);
    this.onTouch();
  }
}
